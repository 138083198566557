<script>
import { ref } from "vue";

export default {
  setup() {
    const activeTab = ref("status-menu"); // Default to 'chat-menu'

    // Method to set the active tab
    const setActiveTab = (tabId) => {
      activeTab.value = tabId;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
  mounted() {
    document.querySelectorAll(".chat-user-list").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const showChat = document.querySelector(".chat-messages");
          if (showChat) {
            showChat.classList.add("show");
          }
        }
      });
    });
    document.querySelectorAll(".chat-close").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const hideChat = document.querySelector(".chat-messages");
          if (hideChat) {
            hideChat.classList.remove("show");
          }
        }
      });
    });
  },
};
</script>

<template>
  <!-- content -->
  <div class="content main_content" style="overflow : hidden;">
    <!-- Left Sidebar Menu -->
    <div class="sidebar-menu">
      <div class="logo">
        <router-link to="index" class="logo-normal"
          ><img src="@/assets/img/logo.svg" alt="Logo"
        /></router-link>
      </div>
      <div class="menu-wrap">
        <div class="main-menu">
          <ul class="nav">
            <a-tooltip placement="right">
              <template #title>Chats</template>
              <li>
                <router-link
                  to="index"
                  @click.prevent="setActiveTab('chat-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-menu"
                >
                  <i class="ti ti-message-2-heart"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Contacts</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('contact-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-menu"
                >
                  <i class="ti ti-user-shield"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Groups</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('group-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#group-menu"
                >
                  <i class="ti ti-users-group"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Status</template>
              <li>
                <a
                  href="#"
                  class="active"
                  @click.prevent="setActiveTab('status-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#status-menu"
                >
                  <i class="ti ti-circle-dot"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Calls</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('call-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#call-menu"
                >
                  <i class="ti ti-phone-call"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Profile</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('profile-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-menu"
                >
                  <i class="ti ti-user-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Settings</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('setting-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#setting-menu"
                >
                  <i class="ti ti-settings"></i>
                </a>
              </li>
            </a-tooltip>
          </ul>
        </div>
        <profile-menu></profile-menu>
      </div>
    </div>
    <!-- /Left Sidebar Menu -->

    <!-- sidebar group -->
    <div class="sidebar-group left-sidebar chat_sidebar flex-shrink-0">
      <div class="tab-content">
        <div
          id="chat-menu"
          :class="['tab-pane', 'fade', activeTab === 'chat-menu' ? 'active show' : '']"
        >
          <!-- Chats sidebar -->
          <index-sidebar></index-sidebar>
          <!-- / Chats sidebar -->
        </div>

        <!-- Contact -->
        <div
          id="contact-menu"
          :class="['tab-pane', 'fade', activeTab === 'contact-menu' ? 'active show' : '']"
        >
          <!-- Chats sidebar -->
          <chat-sidebar></chat-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Contact -->

        <!-- Group -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'group-menu' ? 'active show' : '']"
          id="group-menu"
        >
          <!-- Chats sidebar -->
          <group-sidebar></group-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Group -->

        <!-- Status -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'status-menu' ? 'active show' : '']"
          id="status-menu"
        >
          <status-sidebar></status-sidebar>
        </div>
        <!-- /Status -->

        <!-- Profile -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'profile-menu' ? 'active show' : '']"
          id="profile-menu"
        >
          <!-- Profile sidebar -->
          <profile-sidebar></profile-sidebar>
          <!-- / Profile sidebar -->
        </div>
        <!-- /Profile -->

        <!-- Calls -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'call-menu' ? 'active show' : '']"
          id="call-menu"
        >
          <!-- Call sidebar -->
          <calls-sidebar></calls-sidebar>
          <!-- / Call sidebar -->
        </div>
        <!-- /Calls -->

        <!-- Settings -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'setting-menu' ? 'active show' : '']"
          id="setting-menu"
        >
          <!-- Settings sidebar -->
          <settings-sidebar></settings-sidebar>
          <!-- / Settings sidebar -->
        </div>
        <!-- /Settings -->
      </div>
    </div>
    <!-- /Sidebar group -->

    <!-- Chat -->
    <div class="chat chat-messages show status-msg justify-content-center">
      <div class="user-status-group">
        <div class="d-xl-none">
          <a class="text-muted chat-close mb-3 d-block" href="#">
            <i class="fas fa-arrow-left me-2"></i>Back
          </a>
        </div>
        <!-- Status-->
        <div class="user-stories-box">
          <div class="inner-popup">
            <div
              id="carouselIndicators"
              class="carousel slide slider"
              data-bs-ride="carousel"
            >
              <div class="status-user-blk">
                <div class="user-details">
                  <div class="avatar avatar-lg me-2">
                    <img
                      src="@/assets/img/profiles/avatar-01.jpg"
                      class="rounded-circle"
                      alt="image"
                    />
                  </div>
                  <div class="user-online">
                    <h5>My Status</h5>
                    <span>Today at 7:15 AM</span>
                  </div>
                </div>
                <div class="status-voice-group">
                  <a href="javascript:;" class="status-pause me-4"
                    ><i class="ti ti-player-pause"></i
                  ></a>
                  <a href="javascript:;" class="text-white me-2 fs-24"
                    ><i class="ti ti-volume"></i
                  ></a>
                  <a href="javascript:;" class="text-white fs-24"
                    ><i class="ti ti-maximize"></i
                  ></a>
                </div>
              </div>
              <ol class="carousel-indicators">
                <li
                  data-bs-target="#carouselIndicators"
                  data-bs-slide-to="0"
                  class="active"
                ></li>
                <li data-bs-target="#carouselIndicators" data-bs-slide-to="1"></li>
                <li data-bs-target="#carouselIndicators" data-bs-slide-to="2"></li>
                <li data-bs-target="#carouselIndicators" data-bs-slide-to="3"></li>
                <li data-bs-target="#carouselIndicators" data-bs-slide-to="4"></li>
              </ol>
              <div class="carousel-inner status_slider" role="listbox">
                <div id="target" class="carousel-item active">
                  <img src="@/assets/img/status/status-01.jpg" alt="Image" />
                </div>
                <div class="carousel-item">
                  <img src="@/assets/img/status/status-02.jpg" alt="Image" />
                </div>
                <div class="carousel-item">
                  <img src="@/assets/img/status/status-03.jpg" alt="Image" />
                </div>
                <div class="carousel-item">
                  <img src="@/assets/img/status/status-04.jpg" alt="Image" />
                </div>
                <div class="carousel-item">
                  <img src="@/assets/img/status/status-05.jpg" alt="Image" />
                </div>
              </div>
              <a
                class="view-status-list br-8 py-1 px-2 position-absolute"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#view-status"
              >
                <i class="ti ti-eye-check me-2"></i>
                <span class="text-gray-9">25</span>
              </a>
            </div>
          </div>
        </div>
        <!-- /Status -->
      </div>
    </div>
    <!-- /Chat -->
  </div>
  <!-- /Content -->

  <modal-index></modal-index>
</template>
