<template>
  <div class="wave-container">
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
    <span class="wave"></span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wave-container {
  position: relative;
  min-width: 100px;
}
.wave {
  display: block;
  bottom: 0px;
  width: 5px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: audio-wave 1.5s infinite ease-in-out;
  border-radius: 5px;
}
.wave:nth-child(2) {
  left: 11px;
  animation-delay: 0.2s;
}

.wave:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}

.wave:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}

.wave:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}
.wave:nth-child(6) {
  left: 55px;
  animation-delay: 1s;
}
.wave:nth-child(7) {
  left: 66px;
  animation-delay: 1.2s;
}
.wave:nth-child(8) {
  left: 77px;
  animation-delay: 1.4s;
}
.wave:nth-child(9) {
  left: 88px;
  animation-delay: 1.6s;
}
.wave:nth-child(10) {
  left: 99px;
  animation-delay: 1.8s;
}
.wave:nth-child(11) {
  left: 110px;
  animation-delay: 2.0s;
}
.wave:nth-child(12) {
  left: 121px;
  animation-delay: 2.2s;
}
@keyframes audio-wave {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #9b59b6;
  }
  25% {
    height: 40px;
    transform: translateY(20px);
    background: #3498db;
  }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #9b59b6;
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #9b59b6;
  }
}
</style>