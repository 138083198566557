import { createRouter, createWebHistory } from 'vue-router';

// **************  Frontend   ************** //

import Signin from '@/views/frontend/pages/signin-index.vue'
import Signup from '@/views/frontend/pages/signup-index.vue'
import Reset_Password from '@/views/frontend/pages/reset-password.vue'
import Forgot_Password from '@/views/frontend/pages/forgot-password.vue'
import Success from '@/views/frontend/pages/success-index.vue'
import Otp from '@/views/frontend/pages/otp-index.vue'
import IndexPage from '@/views/frontend/pages/index/index-page.vue'
import ChatIndex from '@/views/frontend/pages/chat/chat-index.vue'
import Status from '@/views/frontend/pages/status/status-index.vue' 
import MyStatus from '@/views/frontend/pages/status/my-status.vue' 
import UserStatus from '@/views/frontend/pages/status/user-status.vue' 
import Group_Chat from '@/views/frontend/pages/group-chat/group-chat.vue'
import All_Calls from '@/views/frontend/pages/all-calls/all-calls.vue'

// **************  Admin   ************** //
// import Admin_Login from '@/views/admin/pages/admin-login.vue';
// import Admin_Forgot_Password from '@/views/admin/pages/forgot-password.vue';
// import Admin_Reset_Password from '@/views/admin/pages/reset-password.vue';
// import Admin_Reset_Password_Success from '@/views/admin/pages/reset-password-success.vue';
// import Admin_Dashboard from '@/views/admin/pages/dashboard/admin-dashboard.vue';
// import Admin_User from '@/views/admin/pages/user/admin-user.vue'
// import User_List from '@/views/admin/pages/user/user/user-list.vue';
// import Block_User from '@/views/admin/pages/user/block-user/block-user.vue';
// import Report_User from '@/views/admin/pages/user/report-user/report-user.vue';
// import Invite_User from '@/views/admin/pages/user/invite-user/invite-user.vue';
// import AdminChat from '@/views/admin/pages/chat/admin-chat.vue'
// import AdminCall from '@/views/admin/pages/call/admin-call.vue'
// import AdminGroup from '@/views/admin/pages/group/admin-group.vue'
// import AdminAbuseMessage from '@/views/admin/pages/abuse-message/admin-abuse-message.vue';
// import AdminStories from '@/views/admin/pages/stories/admin-stories.vue'
// import General_Settings from '@/views/admin/pages/settings/general-settings/general-settings.vue'
// import Profile_Settings from '@/views/admin/pages/settings/general-settings/profile-settings.vue'
// import Change_Password from '@/views/admin/pages/settings/general-settings/change-password.vue'
// import Notification_Settings from '@/views/admin/pages/settings/general-settings/notification-settings.vue'
// import App_Settings from '@/views/admin/pages/settings/app-settings/app-settings.vue'
// import Company_Settings from '@/views/admin/pages/settings/app-settings/company-settings.vue'
// import Authentication_Settings from '@/views/admin/pages/settings/app-settings/authentication-settings.vue'
// import Social_Auth from '@/views/admin/pages/settings/app-settings/social-auth.vue'
// import Chat_Settings from '@/views/admin/pages/settings/app-settings/chat-settings.vue'
// import Video_Audio_Settings from '@/views/admin/pages/settings/app-settings/video-audio-settings.vue'
// import Custom_Fields from '@/views/admin/pages/settings/app-settings/custom-fields.vue'
// import Integrations_Index from '@/views/admin/pages/settings/app-settings/integrations-index.vue'
// import System_Settings from '@/views/admin/pages/settings/system-settings/system-settings.vue'
// import Localization_Settings from '@/views/admin/pages/settings/system-settings/localization-settings.vue'
// import Email_Settings from '@/views/admin/pages/settings/system-settings/email-settings.vue'
// import Sms_Settings from '@/views/admin/pages/settings/system-settings/sms-settings.vue'
// import Otp_Settings from '@/views/admin/pages/settings/system-settings/otp-settings.vue'
// import Language_Settings from '@/views/admin/pages/settings/system-settings/language-settings.vue'
// import Add_Language from '@/views/admin/pages/settings/system-settings/add-language.vue'
// import Language_Web from '@/views/admin/pages/settings/system-settings/language-web.vue'
// import Gdpr_Settings from '@/views/admin/pages/settings/system-settings/gdpr-settings.vue'
// import Theme_Settings from '@/views/admin/pages/settings/theme-settings/theme-settings.vue';
// import Appearance_Settings from '@/views/admin/pages/settings/theme-settings/appearance-settings.vue';
// import Others_Settings from '@/views/admin/pages/settings/others-settings/others-settings.vue';
// import Storage_Settings from '@/views/admin/pages/settings/others-settings/storage-settings.vue';
// import Backup_Settings from '@/views/admin/pages/settings/others-settings/backup-settings.vue';
// import Clear_Cache from '@/views/admin/pages/settings/others-settings/clear-cache.vue';
// import Ban_Address from '@/views/admin/pages/settings/others-settings/ban-address.vue';

// import Tables from '@/views/admin/pages/uiinterface/tables/ui-tables'
// import Tables_Basic from '@/views/admin/pages/uiinterface/tables/tables-basic.vue'
// import Data_Tables from '@/views/admin/pages/uiinterface/tables/data-tables.vue'
// import Chartapex from '@/views/admin/pages/uiinterface/charts/apex/chart-apex.vue'
// import Chartc3 from '@/views/admin/pages/uiinterface/charts/c3/chart-c3.vue'
// import Chartflot from '@/views/admin/pages/uiinterface/charts/flot/chart-flot.vue'
// import Chartjs from '@/views/admin/pages/uiinterface/charts/js/chart-js.vue'
// import Chartmorris from '@/views/admin/pages/uiinterface/charts/morris/chart-morris.vue'
// import Charts from '@/views/admin/pages/uiinterface/charts/ui-charts.vue'
// import Forms from '@/views/admin/pages/uiinterface/forms/ui-forms.vue'
// import Formbasicinput from '@/views/admin/pages/uiinterface/forms/form-elements/formbasic/formbasic-input.vue'
// import Formcheckboxradios from '@/views/admin/pages/uiinterface/forms/form-elements/form-checkbox-radios.vue'
// import Formgridgutters from '@/views/admin/pages/uiinterface/forms/form-elements/form-grid-gutters.vue'
// import Formselect from '@/views/admin/pages/uiinterface/forms/form-elements/form-select.vue'
// import FormInput from '@/views/admin/pages/uiinterface/forms/form-elements/forminput/form-input.vue'
// import Formmask from '@/views/admin/pages/uiinterface/forms/form-elements/formmask/formmask.vue'
// import Formfileupload from '@/views/admin/pages/uiinterface/forms/form-elements/formfile-upload.vue'
// import FormHorizontal from '@/views/admin/pages/uiinterface/forms/layouts/form-horizontal.vue'
// import Formvertical from '@/views/admin/pages/uiinterface/forms/layouts/form-vertical.vue'
// import Formfloatinglabels from '@/views/admin/pages/uiinterface/forms/layouts/form-floating-labels.vue'
// import Formvalidation from '@/views/admin/pages/uiinterface/forms/form-validation.vue'
// import Formselect2 from '@/views/admin/pages/uiinterface/forms/form-select2.vue'
// import Formelements from '@/views/admin/pages/uiinterface/forms/form-elements/form-elements.vue'
// import Formwizard from '@/views/admin/pages/uiinterface/forms/form-wizard.vue'
// import Advancedui from '@/views/admin/pages/uiinterface/advancedUI/advanced-ui.vue'
// import UI_Ribbon from '@/views/admin/pages/uiinterface/advancedUI/ui-ribbon.vue'
// import UI_Clipboard from '@/views/admin/pages/uiinterface/advancedUI/ui-clipboard.vue'
// import UI_Drag_Drop from '@/views/admin/pages/uiinterface/advancedUI/ui-drag-drop.vue'
// import UI_Text_Editor from '@/views/admin/pages/uiinterface/advancedUI/ui-text-editor.vue'
// import UI_Counter from '@/views/admin/pages/uiinterface/advancedUI/ui-counter.vue'
// import UI_Scrollbar from '@/views/admin/pages/uiinterface/advancedUI/ui-scrollbar.vue'
// import UI_Rating from '@/views/admin/pages/uiinterface/advancedUI/ui-rating.vue'
// import UI_Stickynote from '@/views/admin/pages/uiinterface/advancedUI/ui-stickynote.vue'
// import UI_Timeline from '@/views/admin/pages/uiinterface/advancedUI/ui-timeline.vue'
// import BaseUi from '@/views/admin/pages/uiinterface/baseui/base-ui.vue'
// import UI_Alerts from '@/views/admin/pages/uiinterface/baseui/ui-alerts.vue'
// import UI_Accordion from '@/views/admin/pages/uiinterface/baseui/ui-accordion.vue'
// import UI_Avatar from '@/views/admin/pages/uiinterface/baseui/ui-avatar.vue'
// import UI_Badges from '@/views/admin/pages/uiinterface/baseui/ui-badges.vue'
// import UI_Borders from '@/views/admin/pages/uiinterface/baseui/ui-borders.vue'
// import UI_Buttons from '@/views/admin/pages/uiinterface/baseui/ui-buttons.vue'
// import UI_Buttons_group from '@/views/admin/pages/uiinterface/baseui/ui-buttons-group.vue'
// import UI_Breadcrumb from '@/views/admin/pages/uiinterface/baseui/ui-breadcrumb.vue'
// import UI_Cards from '@/views/admin/pages/uiinterface/baseui/ui-cards.vue'
// import UI_Carousel from '@/views/admin/pages/uiinterface/baseui/carousel/ui-carousel.vue'
// import UI_Colors from '@/views/admin/pages/uiinterface/baseui/ui-colors.vue'
// import UI_Dropdowns from '@/views/admin/pages/uiinterface/baseui/ui-dropdowns.vue'
// import UI_Grid from '@/views/admin/pages/uiinterface/baseui/ui-grid.vue'
// import UI_Images from '@/views/admin/pages/uiinterface/baseui/ui-images.vue'
// import UI_Lightbox from '@/views/admin/pages/uiinterface/baseui/lightbox/ui-lightbox.vue'
// import UI_Media from '@/views/admin/pages/uiinterface/baseui/ui-media.vue'
// import UI_Modals from '@/views/admin/pages/uiinterface/baseui/ui-modals.vue'
// import UI_Offcanvas from '@/views/admin/pages/uiinterface/baseui/ui-offcanvas.vue'
// import UI_Pagination from '@/views/admin/pages/uiinterface/baseui/ui-pagination.vue'
// import UI_Popovers from '@/views/admin/pages/uiinterface/baseui/ui-popovers.vue'
// import UI_Progress from '@/views/admin/pages/uiinterface/baseui/ui-progress.vue'
// import UI_Placeholders from '@/views/admin/pages/uiinterface/baseui/ui-placeholders.vue'
// import UI_Rangeslider from '@/views/admin/pages/uiinterface/advancedUI/ui-rangeslider.vue'
// import Ui_Navtabs from '@/views/admin/pages/uiinterface/baseui/ui-nav-tabs.vue'
// import UI_Spinner from '@/views/admin/pages/uiinterface/baseui/ui-spinner.vue'
// import UI_Sweetalerts from '@/views/admin/pages/uiinterface/baseui/ui-sweetalerts.vue'
// import UI_Tooltips from '@/views/admin/pages/uiinterface/baseui/ui-tooltips.vue'
// import UI_Typography from '@/views/admin/pages/uiinterface/baseui/ui-typography.vue'
// import UI_Video from '@/views/admin/pages/uiinterface/baseui/ui-video.vue'
// import Icons from '@/views/admin/pages/uiinterface/icons/ui-icons.vue'
// import UI_Iconfontawesome from "@/views/admin/pages/uiinterface/icons/icon-fontawesome.vue";
// import UI_Iconfeather from "@/views/admin/pages/uiinterface/icons/icon-feather.vue";
// import UI_Iconionic from "@/views/admin/pages/uiinterface/icons/icon-ionic.vue";
// import UI_Iconmaterial from "@/views/admin/pages/uiinterface/icons/icon-material.vue";
// import UI_Iconpe7 from "@/views/admin/pages/uiinterface/icons/icon-pe7.vue";
// import UI_Iconsimpleline from "@/views/admin/pages/uiinterface/icons/icon-simpleline.vue";
// import UI_Iconthemify from "@/views/admin/pages/uiinterface/icons/icon-themify.vue";
// import UI_Iconweather from "@/views/admin/pages/uiinterface/icons/icon-weather.vue";
// import UI_Icontypicon from "@/views/admin/pages/uiinterface/icons/icon-typicon.vue";
// import UI_Iconflag from "@/views/admin/pages/uiinterface/icons/icon-flag.vue";


const routes = [
  {
    path: '/',
    name: 'signin-index',
    component: Signin
  },
  {
    path: '/signup',
    name: 'signup-index',
    component: Signup
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: Reset_Password
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgot_Password
  },
  {
    path: '/success',
    name: 'success-index',
    component: Success
  },
  {
    path: '/otp',
    name: 'otp-index',
    component: Otp
  },
  {
    path: '/chat/:alias/:id/',
    params: true,
    name: 'chat-index',
    component: ChatIndex
  },
  {
    path: '/index',
    name: 'index-page',
    component: IndexPage
  },
  {
    path: '/status',
    name: 'status-index',
    component: Status
  },
  {
    path: '/my-status',
    name: 'my-status',
    component: MyStatus
  },
  {
    path: '/user-status',
    name: 'user-status',
    component: UserStatus
  },
  {
    path: '/group-chat',
    name: 'group-chat',
    component: Group_Chat
  },
  {
    path: '/all-calls',
    name: 'all-calls',
    component: All_Calls
  },


// **************  Admin   ************** //

  // {
  //   path: '/admin-template/index',
  //   name: 'admin-template/index',
  //   component: Admin_Dashboard
  // },
  // {
  //   path: '/admin-template/',
  //   name: 'admin-template/',
  //   component: Admin_Login
  // },
  // {
  //   path: '/admin-template/forgot-password',
  //   name: 'admin-template/forgot-password',
  //   component: Admin_Forgot_Password
  // },
  // {
  //   path: '/admin-template/reset-password',
  //   name: 'admin-template/reset-password',
  //   component: Admin_Reset_Password
  // },
  // {
  //   path: '/admin-template/reset-password-success',
  //   name: 'admin-template/reset-password-success',
  //   component: Admin_Reset_Password_Success
  // },
  // {
  //   path: '/admin-template/user',
  //   component: Admin_User,
  //   children: [
  //     { path: '', redirect: '/admin-template/user/user-list' },
  //     { path: "user-list", component: User_List },
  //     { path: "block-user", component: Block_User },
  //     { path: "report-user", component: Report_User },
  //     { path: "invite-user", component: Invite_User },
  //   ]
  // },
  // {
  //   path: '/admin-template/chat',
  //   name: 'admin-chat',
  //   component: AdminChat
  // },
  // {
  //   path: '/admin-template/call',
  //   name: 'admin-call',
  //   component: AdminCall
  // },
  // {
  //   path: '/admin-template/group',
  //   name: 'admin-group',
  //   component: AdminGroup
  // },
  // {
  //   path: '/admin-template/abuse-message',
  //   name: 'admin-abuse-message',
  //   component: AdminAbuseMessage
  // },
  // {
  //   path: '/admin-template/stories',
  //   name: 'admin-stories',
  //   component: AdminStories
  // },
  // {
  //   path: '/admin-template/settings',
  //   component: General_Settings,
  //   children: [
  //     { path: '', redirect: '/admin-template/settings/profile-settings' },
  //     { path: "profile-settings", component: Profile_Settings },
  //     { path: "change-password", component: Change_Password },
  //     { path: "notification-settings", component: Notification_Settings },
  //   ]
  // },
  // {
  //   path: '/admin-template/app-settings',
  //   component: App_Settings,
  //   children: [
  //     { path: '', redirect: '/admin-template/app-settings/company-settings' },
  //     { path: "company-settings", component: Company_Settings },
  //     { path: "authentication-settings", component: Authentication_Settings },
  //     { path: "social-auth", component: Social_Auth },
  //     { path: "chat-settings", component: Chat_Settings },
  //     { path: "video-audio-settings", component: Video_Audio_Settings },
  //     { path: "custom-fields", component: Custom_Fields },
  //     { path: "integrations", component: Integrations_Index },
  //   ]
  // },
  // {
  //   path: '/admin-template/system-settings',
  //   component: System_Settings,
  //   children: [
  //     { path: '', redirect: '/admin-template/system-settings/localization-settings' },
  //     { path: "localization-settings", component: Localization_Settings },
  //     { path: "email-settings", component: Email_Settings },
  //     { path: "sms-settings", component: Sms_Settings },
  //     { path: "otp", component: Otp_Settings },
  //     { path: "language", component: Language_Settings },
  //     { path: "add-language", component: Add_Language },
  //     { path: "language-web", component: Language_Web },
  //     { path: "gdpr", component: Gdpr_Settings },
  //   ]
  // },
  // {
  //   path: '/admin-template/theme-settings',
  //   component: Theme_Settings,
  //   children: [
  //     { path: '', redirect: '/admin-template/theme-settings/appearance-settings' },
  //     { path: "appearance-settings", component: Appearance_Settings },
  //   ]
  // },
  // {
  //   path: '/admin-template/others-settings',
  //   component: Others_Settings,
  //   children: [
  //     { path: '', redirect: '/admin-template/others-settings/storage' },
  //     { path: "storage", component: Storage_Settings },
  //     { path: "backup", component: Backup_Settings },
  //     { path: "clear-cache", component: Clear_Cache },
  //     { path: "ban-address", component: Ban_Address },
  //   ]
  // },
  // {
  //   path: '/admin-template/icons',
  //   component: Icons,
  //   children: [
  //     { path: '', redirect: '/admin-template/icons/icon-fontawesome' },
  //     { path: "icon-fontawesome", component: UI_Iconfontawesome },
  //     { path: "icon-feather", component: UI_Iconfeather },
  //     { path: "icon-ionic", component: UI_Iconionic },
  //     { path: "icon-material", component: UI_Iconmaterial },
  //     { path: "icon-pe7", component: UI_Iconpe7 },
  //     { path: "icon-simpleline", component: UI_Iconsimpleline },
  //     { path: "icon-themify", component: UI_Iconthemify },
  //     { path: "icon-weather", component: UI_Iconweather },
  //     { path: "icon-typicon", component: UI_Icontypicon },
  //     { path: "icon-flag", component: UI_Iconflag },
  //   ]
  // },
  // {
  //   path: '/admin-template/baseui',
  //   component: BaseUi,
  //   children: [
  //     { path: '', redirect: '/admin-template/baseui/ui-alerts' },
  //     { path: "ui-alerts", component: UI_Alerts },
  //     { path: "ui-accordion", component: UI_Accordion },
  //     { path: "ui-avatar", component: UI_Avatar },
  //     { path: "ui-badges", component: UI_Badges },
  //     { path: "ui-borders", component: UI_Borders },
  //     { path: "ui-buttons", component: UI_Buttons },
  //     { path: "ui-buttons-group", component: UI_Buttons_group },
  //     { path: "ui-breadcrumb", component: UI_Breadcrumb },
  //     { path: "ui-cards", component: UI_Cards },
  //     { path: "ui-carousel", component: UI_Carousel },
  //     { path: "ui-colors", component: UI_Colors },
  //     { path: "ui-dropdowns", component: UI_Dropdowns },
  //     { path: "ui-grid", component: UI_Grid },
  //     { path: "ui-images", component: UI_Images },
  //     { path: "ui-lightbox", component: UI_Lightbox},
  //     { path: "ui-media", component: UI_Media},
  //     { path: "ui-modals", component: UI_Modals},
  //     { path: "ui-offcanvas", component: UI_Offcanvas},
  //     { path: "ui-pagination", component: UI_Pagination},
  //     { path: "ui-popovers", component: UI_Popovers},
  //     { path: "ui-progress", component: UI_Progress},
  //     { path: "ui-placeholders", component: UI_Placeholders},
  //     { path: "ui-nav-tabs", component: Ui_Navtabs},
  //     { path: "ui-spinner", component: UI_Spinner},
  //     { path: "ui-sweetalerts", component: UI_Sweetalerts},
  //     { path: "ui-tooltips", component: UI_Tooltips},
  //     { path: "ui-typography", component: UI_Typography},
  //     { path: "ui-video", component: UI_Video},
  //   ]
  // },
  // {
  //   path: '/admin-template/advancedui',
  //   component: Advancedui,
  //   children: [
  //     { path: '', redirect: '/admin-template/advancedui/ui-ribbon' },
  //     { path: "ui-ribbon", component: UI_Ribbon },
  //     { path: "ui-clipboard", component: UI_Clipboard },
  //     { path: "ui-drag-drop", component: UI_Drag_Drop },
  //     { path: "ui-text-editor", component: UI_Text_Editor },
  //     { path: "ui-counter", component: UI_Counter },
  //     { path: "ui-scrollbar", component: UI_Scrollbar },
  //     { path: "ui-rating", component: UI_Rating },
  //     { path: "ui-stickynote", component: UI_Stickynote },
  //     { path: "ui-rangeslider", component: UI_Rangeslider},
  //     { path: "ui-timeline", component: UI_Timeline }
  //   ]
  // },
  // {
  //   path: '/admin-template/tables',
  //   component: Tables,
  //   children: [
  //     { path: '', redirect: '/admin-template/tables/data-tables' },
  //     { path: "data-tables", component: Data_Tables },
  //     { path: "tables-basic", component: Tables_Basic }
  //   ]
  // },
  // {
  //   path: '/admin-template/charts',
  //   component: Charts,
  //   children: [
  //     { path: '', redirect: '/admin-template/charts/chart-apex' },
  //     { path: "chart-apex", component: Chartapex },
  //     { path: "chart-c3", component: Chartc3 },
  //     { path: "chart-flot", component: Chartflot },
  //     { path: "chart-js", component: Chartjs },
  //     { path: "chart-morris", component: Chartmorris }
  //   ]
  // },
  // {
  //   path: '/admin-template/forms',
  //   component: Forms,
  //   children: [
  //     { path: '', redirect: '/admin-template/forms/form-basic-inputs' },
  //     { path: "form-basic-inputs", component: Formbasicinput },
  //     { path: "form-checkbox-radios", component: Formcheckboxradios },
  //     { path: "form-grid-gutters", component: Formgridgutters },
  //     { path: "form-input-groups", component: FormInput },
  //     { path: "form-select", component: Formselect },
  //     { path: "form-mask", component: Formmask },
  //     { path: "form-fileupload", component: Formfileupload },
  //     { path: "form-horizontal", component: FormHorizontal },
  //     { path: "form-vertical", component: Formvertical },
  //     { path: "form-floating-labels", component: Formfloatinglabels },
  //     { path: "form-validation", component: Formvalidation },
  //     { path: "form-select2", component: Formselect2 },
  //     { path: "form-elements", component: Formelements },
  //     { path: "form-wizard", component: Formwizard },
  //   ]
  // },

];

export const router = createRouter({
  history: createWebHistory('/vue/template/'),
  linkActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: 'smooth' });
  next();
});

