<template>
    <div class="chat-actions">
        <a class="#" href="#" data-bs-toggle="dropdown">
            <i class="ti ti-dots-vertical"></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-end p-3">
            <li>
                <a class="dropdown-item" href="#" @click="replyMessage"><i
                        class="ti ti-arrow-back-up me-2"></i>Reply</a>
            </li>
            <!-- <li>
                <a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Forward</a>
            </li> -->
            <!-- <li>
                <a class="dropdown-item" href="#"><i class="ti ti-file-export me-2"></i>Copy</a>
            </li> -->
            <!-- <li>
                <a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a>
            </li> -->
            <li v-show="myOwnMessage">
                <a class="dropdown-item" href="#" data-bs-toggle="modal"  :data-bs-target="`#message-delete${messageId}`" ><i
                        class="ti ti-trash me-2"></i>Delete</a>
            </li>
            <li v-show="myOwnMessage">
                <a class="dropdown-item" href="#" @click="deleteMessage" ><i
                        class="ti ti-trash me-2"></i>Delete1</a>
            </li>
            <li v-show="myOwnMessage && isEditable">
                <a class="dropdown-item" href="#" @click="editMessage"><i
                        class="ti ti-pencil me-2"></i>Edit</a>
            </li>
            <!-- <li>
                <a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a>
            </li> -->
            <!-- <li>
                <a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archeive Chat</a>
            </li> -->
            <!-- <li>
                <a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chat</a>
            </li> -->
        </ul>
    </div>

      <!-- Delete -->
  <div class="modal fade" :id="`message-delete${messageId}`">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Delete Chat</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="deleteMessage">
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                checked
                name="delete-chat"
                value="me"
                v-model="deleteChoice"
              />
              <label class="form-check-label">Delete For Me</label>
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                name="delete-chat"
                value="everyone"
                v-model="deleteChoice"
              />
              <label class="form-check-label"
                >Delete For Everyone</label
              >
            </div>
            <div class="row g-3">
              <div class="col-6">
                <a
                  href="#"
                  class="btn btn-outline-primary w-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >Cancel</a
                >
              </div>
              <div class="col-6">
                <button
                  class="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Delete
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- Report Group -->
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>
<script>


export default {
    props: {

        myOwnMessage: {
            type: Boolean,
            required: true
        },
        messageId: {
            type: String,
            required: true
        },
        isEditable : {
          type : Boolean,
          required : false,
          default : true
        }
    },
    data () {
      return {
        deleteChoice : 'me'
      }
    },
    name: 'ChatActions',
    methods: {
        replyMessage() {       
            this.$emit('reply-message', this.messageId);
        },
        editMessage() {
            this.$emit('edit-message', this.messageId);
        },
        deleteMessage() {
            this.$emit('delete-message', {messageId : this.messageId , choice : this.deleteChoice});
        }
 
    }
}
</script>

<style scoped>
.chat-actions {
    position: relative;
}

.dropdown-menu {
    min-width: 200px;
}

.dropdown-item i {
    margin-right: 8px;
}
</style>