<template>
  <div class="chat-users-wrap">
    <div v-if="!threadsUsers">
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
    </div>
    <div v-else-if="threadsUsers && !(searchResults.users?.length || searchResults.conversations?.length)">
      <div class="chat-list" v-for="user in threadsUsers" :key="user.id">
        <router-link
          :to="`/chat/${user.resources.recipient.provider_alias}/${user.resources.recipient.provider_id}`"
          @click="this.$store.commit('setCurrentChat', user)"
          class="chat-user-list"
        >
          <div
            class="avatar avatar-lg me-2"
            :class="user.options?.online_status ? 'online' : 'offline'"
          >
            <!-- <img
              src="@/assets/img/profiles/avatar-01.jpg"
              class="rounded-circle border border-warning border-2"
              alt="image"
            /> -->
            <img
              :src="user.resources?.recipient?.avatar?.sm"
              class="rounded-circle border border-warning border-2"
              alt="image"
            />
          </div>
          <div class="chat-user-info">
            <div class="chat-user-msg">
              <h6>{{ user.name }}</h6>
              <p>({{ user.resources.recipient.provider_alias }})</p>
              <p
                style="
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                "
              >
                <span
                  v-if="user.resources.latest_message.type_verbose == 'MESSAGE'"
                  >{{ user.resources.latest_message.body }}</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'AUDIO_MESSAGE'
                  "
                  >Audio File</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'VIDEO_MESSAGE'
                  "
                  >Video File</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'DOCUMENT_MESSAGE'
                  "
                  >Document</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'IMAGE_MESSAGE'
                  "
                  >Image</span
                >
              </p>
              <!-- <p><span class="animate-typing">is typing
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            </span>
                        </p> -->
            </div>
            <div class="chat-user-time">
              <div class="chat-pin" v-if="user.unread_count > 0 && user.unread">
                <i class="ti ti-pin me-2"></i>
                <span class="count-message fs-12 fw-semibold">{{
                  user.unread_count
                }}</span>
              </div>
              <span class="time">{{
                formatTime(user.resources.latest_message.created_at)
              }}</span>
            </div>
          </div>
        </router-link>
        <div class="chat-dropdown">
          <a class="#" href="#" data-bs-toggle="dropdown">
            <i class="ti ti-dots-vertical"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end p-3">
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-box-align-right me-2"></i>Archive Chat</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-check me-2"></i>Mark as Unread</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-pinned me-2"></i>Pin Chats</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#delete-chat"
                ><i class="ti ti-trash me-2"></i>Delete</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <h5 v-if="searchResults.conversations?.length" class="mt-3 mb-3">Search Results ({{searchResults.conversations.length}})</h5>
    <div
      v-for="contact in searchResults.conversations"
      :key="contact.id"
      class="contact-user d-flex align-items-center justify-content-between"
      style="cursor:pointer"
    >
      <div
        class="d-flex align-items-center"
        @click="gotoChatPage(contact.provider_alias, contact.provider_id)"
      >
        <div class="avatar avatar-lg">
          <!-- <img :src="contact.avatar.sm" class="rounded-circle" alt="image" /> -->
          <img
            src="@/assets/img/profiles/avatar-01.jpg"
            class="rounded-circle"
            alt="image"
          />
        </div>
        <div class="ms-2">
          <h6>{{ contact.base.name }}</h6>
          <p>{{ contact.base.email }}</p>
        </div>
      </div>
    </div>

    <h5 v-if="searchResults.users?.length" class="mt-3 mb-3">Users ({{searchResults.users.length}})</h5>
    <div
      v-for="user in searchResults.users"
      :key="user.id"
      class="contact-user d-flex align-items-center justify-content-between"
      style="cursor:pointer"
    >
      <div
        class="d-flex align-items-center"
        @click="gotoChatPage('user', user.id)"
      >
        <div class="avatar avatar-lg">
          <!-- <img :src="contact.avatar.sm" class="rounded-circle" alt="image" /> -->
          <img
            src="@/assets/img/profiles/avatar-01.jpg"
            class="rounded-circle"
            alt="image"
          />
        </div>
        <div class="ms-2">
          <h6>{{ user.name }}</h6>
          <p>{{ user.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
export default {
  name: "AllChatSidebar",
  computed: {
    threadsUsers() {
      return this.$store.getters.threadsUsers;
    },
    searchUsers() {
      return this.$store.getters.searchThreadUsers;
    },
    currentChat() {
      return this.$store.getters.currentChat;
    },
    searchResults () {
      return this.$store.getters.searchResults;
    }
  },
  data() {
    return {
      token: localStorage.getItem("authtoken"),
    };
  },

  methods: {
    formatTime(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    gotoChatPage(alias, id) {
      this.$router.push({ path: `/chat/${alias}/${id}` });
    },
  },
};
</script>