<script>
import { ref, onMounted, watch } from "vue";

export default {
    computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  setup() {
    const isDarkMode = ref(false);

    const toggleDarkMode = () => {
      if (isDarkMode.value) {
        document.body.classList.remove("darkmode");
        localStorage.setItem("darkMode", null);
      } else {
        document.body.classList.add("darkmode");
        localStorage.setItem("darkMode", "enabled");
      }
      isDarkMode.value = !isDarkMode.value;
    };

    const initializeDarkMode = () => {
      const darkMode = localStorage.getItem("darkMode");
      isDarkMode.value = darkMode === "enabled";
    };

    onMounted(() => {
      initializeDarkMode();
    });

    watch(isDarkMode, (newVal) => {
      if (newVal) {
        document.body.classList.add("darkmode");
      } else {
        document.body.classList.remove("darkmode");
      }
    });

    return {
      isDarkMode,
      toggleDarkMode,
    };
  },
};
</script>

<template>
    <div class="profile-menu">
        <ul class="d-flex gap-4 align-items-center">
            <li>
                <a href="#" id="dark-mode-toggle" @click="toggleDarkMode" :class="{ 'dark-mode-toggle active': true, active: isDarkMode }">
                    <i class="ti" :class="[isDarkMode ? 'ti-sun' : 'ti-moon']"></i>
                </a>
            </li>
            <li>
                <div class="dropdown">
                    <a href="#" class="avatar avatar-md" data-bs-toggle="dropdown">
                        <img src="@/assets/img/profiles/avatar-16.jpg" alt="img" class="rounded-circle">
                    </a>
                    <div class="dropdown-menu dropdown-menu-end p-3">
                        <router-link to="/" class="dropdown-item"><i class="ti ti-logout-2 me-2"></i>Logout</router-link>
                    </div>
                </div>   
            </li>
        </ul>
    </div>
</template>




