// src/store/index.js

import { createStore } from 'vuex';

const store = createStore({
  state: {
    // Define your state properties here
    count: 0,
    threadId: null, // Add threadId to the state
    threads:[],
    currentChat : {
      name : '',
      avatar : '',
    },
    searchResults : {
      conversations : [],
      users : [],
    }
  },
  mutations: {
    // Define your mutations here
    increment(state) {
      state.count++;
    },
    setThreadId(state, threadId) {
      state.threadId = threadId; // Mutation to set threadId
    },
    setThreadsUsers(state, threadsUsers) {
      state.threadsUsers = threadsUsers; // Mutation to set threads
    },
    setSearchThreadUsers(state, searchThreadUsers) {
      state.searchThreadUsers = searchThreadUsers; // Mutation to set searchThreadUsers
    },
    setCurrentChat(state , user) {
      console.log('setting - ' , user)
      state.currentChat = user;
    },
    setSearchResults(state , res) {
      state.searchResults = res;
    }
  },
  actions: {
    // Define your actions here
    increment({ commit }) {
      commit('increment');
    },
  },
  getters: {
    // Define your getters here
    count: (state) => state.count,
    threadId: (state) => state.threadId, 
    threadsUsers: (state) => state.threadsUsers,
    searchThreadUsers: (state) => state.searchThreadUsers,
    currentChat : (state) => state.currentChat,
    searchResults : (state) => state.searchResults,
  },
});

export default store;