<template>
  <div class="w-100">
    <div
      class="modal fade"
      :id="`exampleModal${message.id}`"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >x</button>
          </div>
          <div class="modal-body">
            <img :src="message.image?.lg" class="w-100 h-100 rounded-1" style="max-height: 50vh" alt="">
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        previousMessage &&
        previousMessage.created_at &&
        message.created_at.slice(0, 10) ==
          previousMessage.created_at.slice(0, 10)
      "
    ></div>
    <div
      v-else
      class="mb-2 w-100 gap-4 mt-4 d-flex justify-content-center align-items-center"
    >
      <hr
        style="
          height: 1px;
          width: 50%;
          background-color: gray;
          margin-top: -4px;
        "
      />
      <p class="align-self-center" style="min-width: fit-content; height: 20px">
        {{ message.created_at.slice(0, 10) }}
      </p>
      <hr
        style="
          height: 1px;
          width: 50%;
          background-color: gray;
          margin-top: -4px;
        "
      />
    </div>
    <div class="chats chats-left mt-2" style="width: fit-content">
      <div class="chat-avatar" style="margin-top: 8px">
        <!-- <img
        :src="replace(message.owner.avatar.md)"
        class="rounded-circle dreams_chat"
        alt="image"
      /> -->
        <!-- <img
          src="@/assets/img/profiles/avatar-06.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        /> -->
        <img
          :src="message.owner?.avatar?.sm"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
      <div
        class="chat-content p-1 rounded-1 position-relative"
        :class="message.id"
        v-if="message.body != 'Deleted Message'"
      >
        <div class="chat-profile-name">
          <h6>
            {{ message.owner.name }}
            <i class="ti ti-circle-filled fs-7 mx-2"></i>
            <span class="chat-time">{{ formatTime(message.created_at) }}</span>
            <span class="msg-read success"><i class="ti ti-checks"></i></span>
          </h6>
        </div>
        <div class="chat-info">
          <div class="message-content">
            <template v-if="message.reply_to">
              <div class="chat-profile-name">
                <h6>{{ message.reply_to.owner.name }}</h6>
              </div>
              <div
                @click="goToOriginalMessage(message.reply_to)"
                class="message-reply"
              >
                <p
                  style="font-size: 0.9rem"
                  v-if="message.reply_to.type_verbose == 'AUDIO_MESSAGE'"
                  class="d-flex items-center gap-2 align-items-center"
                >
                  <i class="ti ti-files"></i>
                  <span>Voice</span>
                </p>
                <p
                  style="font-size: 0.9rem"
                  v-else-if="message.reply_to.type_verbose == 'VIDEO_MESSAGE'"
                  class="d-flex items-center gap-2 align-items-center"
                >
                  <i class="ti ti-files"></i>
                  <span>Video</span>
                </p>
                <p
                  style="font-size: 0.9rem"
                  v-else-if="
                    message.reply_to.type_verbose == 'DOCUMENT_MESSAGE'
                  "
                  class="d-flex items-center gap-2 align-items-center"
                >
                  <i class="ti ti-files"></i>
                  <span>Document</span>
                </p>
                <img
                  v-else-if="message.reply_to.type_verbose == 'IMAGE_MESSAGE'"
                  class="rounded"
                  style="width: 50px; height: 70px"
                  :src="replace(message.reply_to.body)"
                  alt=""
                />
                <p style="font-size: 0.9rem" v-else>
                  {{ message.reply_to.body }}
                </p>
              </div>
            </template>

            <template v-if="message.type_verbose == 'MESSAGE'">
              {{ message.body }}
            </template>
            <template v-if="message.type_verbose == 'IMAGE_MESSAGE'">
              <button
                type="button"
                @click="showModal = message.image?.lg"
                style="
                  background-color: transparent;
                  padding: 0;
                  margin: 0;
                  border: 0;
                "
                data-bs-toggle="modal"
                :data-bs-target="`#exampleModal${message.id}`"
              >
                <img
                  @click="showModal = message.image?.lg"
                  :src="message.image?.sm"
                  alt=""
                  width="200px"
                />
              </button>
            </template>
            <template v-if="message.type_verbose == 'IMAGE_MESSAGE_TEMP'">
              <img :src="replace(message.body)" alt="" width="200px" />
            </template>

            <template v-if="message.type_verbose == 'AUDIO_MESSAGE'">
              <audio controls>
                <source :src="message.audio" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </template>
            <template v-if="message.type_verbose == 'VIDEO_MESSAGE'">
              <video controls width="200px">
                <source :src="message.video" type="video/mp4" />
                Your browser does not support the audio element.
              </video>
            </template>
            <template v-if="message.type_verbose == 'DOCUMENT_MESSAGE'">
              <div class="file-attach">
                <span class="file-icon">
                  <i class="ti ti-files"></i>
                </span>
                <div class="ms-2 overflow-hidden">
                  <h6 class="mb-1">{{ message.owner.name }}</h6>
                  <p>{{ message.created_at.slice(0, 10) }}</p>
                </div>
                <a
                  @click.prevent="downloadFile(message.document)"
                  class="download-icon"
                >
                  <i class="ti ti-download"></i>
                </a>
              </div>
            </template>

            <template v-if="message.type_verbose == 'AUDIO_MESSAGE_TEMP'">
              <div style="min-height: 30px" class="d-flex align-items-center">
                <sound-wave></sound-wave>
              </div>
            </template>

            <template v-if="message.type_verbose == 'VIDEO_MESSAGE_TEMP'">
              Uploading video ........
            </template>
            <template v-if="message.type_verbose == 'DOCUMENT_MESSAGE_TEMP'">
              Uploading file ........
            </template>

            <div class="emoj-group">
              <ul>
                <li class="emoj-action">
                  <a href="javascript:void(0);" @click="toggleEmojiList"
                    ><i class="ti ti-mood-smile"></i
                  ></a>
                  <div
                    :style="{ display: showEmojiList ? 'block' : 'none' }"
                    class="emoj-group-list"
                  >
                    <ul>
                      <li
                        @click="
                          () => {
                            if (reacted && myReaction.reaction == ':100:') {
                              removeReaction();
                            } else {
                              giveReact(':100:');
                            }
                          }
                        "
                      >
                        <a
                          :class="`${
                            reacted && myReaction.reaction == ':100:'
                              ? 'bg-primary'
                              : ''
                          } rounded-1 p-1`"
                          style="min-width: fit-content"
                          href="javascript:void(0);"
                          >💯</a
                        >
                      </li>
                      <li
                        @click="
                          () => {
                            if (reacted && myReaction.reaction == ':smile:') {
                              removeReaction();
                            } else {
                              giveReact(':smile:');
                            }
                          }
                        "
                      >
                        <a
                          :class="`${
                            reacted && myReaction.reaction == ':smile:'
                              ? 'bg-primary'
                              : ''
                          } rounded-1 p-1`"
                          style="min-width: fit-content"
                          href="javascript:void(0);"
                          >😄</a
                        >
                      </li>
                      <li
                        @click="
                          () => {
                            if (reacted && myReaction.reaction == ':heart:') {
                              removeReaction();
                            } else {
                              giveReact(':heart:');
                            }
                          }
                        "
                      >
                        <a
                          :class="`${
                            reacted && myReaction.reaction == ':heart:'
                              ? 'bg-primary'
                              : ''
                          } rounded-1 p-1`"
                          style="min-width: fit-content"
                          href="javascript:void(0);"
                          >❤️</a
                        >
                      </li>

                      <li class="add-emoj">
                        <a href="javascript:void(0);"
                          ><i class="ti ti-plus"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li @click="replyMessage(message.id)">
                  <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <chat-actions
            :messageId="message.id"
            @replyMessage="replyMessage"
            :myOwnMessage="myOwnMessage"
          >
          </chat-actions>
        </div>
        <div
          class="emonji-wrap position-absolute"
          style="right: 24px; bottom: 12px"
        >
          <a
            v-if="myReaction && myReaction.reaction == ':100:'"
            href="javascript:void(0);"
          >
            <span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >💯</span
            >
          </a>
          <a
            v-else-if="myReaction && myReaction.reaction == ':smile:'"
            href="javascript:void(0);"
            ><span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >😄</span
            ></a
          >
          <a
            v-else-if="myReaction && myReaction.reaction == ':heart:'"
            href="javascript:void(0);"
            ><span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >❤️</span
            ></a
          >
        </div>
        <div class="chat-profile-name">
          <span class="chat-time" v-if="message.edited"
            >(edited)<i class="ti ti-pencil"></i
          ></span>
        </div>
      </div>
      <div
        v-else
        :class="message.id"
        class="chat-content rounded p-1 position-relative"
      >
        <div class="chat-info">
          <div class="message-content bg-danger">
            <p class="d-flex gap-2 align-items-center" style="min-width: 250px">
              <span>Message has been deleted.</span>
              <i style="font-size: 1.5rem" class="ti ti-trash"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chatActions from "./chat-actions.vue";
import SoundWave from "./SoundWave.vue";
export default {
  props: {
    message: {
      type: Object,
      Required: true,
      default: () => {},
    },
    previousMessage: {
      type: Object,
      Required: false,
      default: null,
    },
  },
  components: {
    chatActions,
    SoundWave,
  },
  name: "ChatMessagesFriendView",
  data() {
    return {
      token: localStorage.getItem("authtoken"),
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      name: [],
      showEmojiList: false,
      reactions: {},
      reacted: false,
      myReaction: {
        reaction: "",
        index: -1,
        id: -1,
      },
      img_url:
        this.$config.APIURL +
        "/storage/threads/" +
        this.message.thread_id +
        "/images/",
      audio_url:
        this.$config.APIURL +
        "/storage/threads/" +
        this.message.thread_id +
        "/audio/",
      myOwnMessage: false,
      showModal: '',
    };
  },
  mounted() {
    this.reactions = this.message.reactions?.data ?? {};
    this.reacted = this.message.reacted;
    if (this.message.reacted && this.reactions) {
      console.clear();
      for (const reaction in this.reactions) {
        if (this.reactions[reaction] && this.reactions[reaction].length) {
          this.reactions[reaction].forEach((rt, i) => {
            if (rt.owner_id == this.user.id) {
              this.myReaction = {
                index: i,
                reaction: reaction,
                id: rt.id,
              };
            }
          });
        }
      }
      console.log(this.myReaction);
    }
  },
  methods: {
    removeReaction() {
      const id = this.myReaction.id; // supposed to be reaction id I gave;
      this.reacted = false;
      this.reactions[this.myReaction.reaction] = this.reactions[
        this.myReaction.reaction
      ].filter((rt, i) => i != this.myReaction.index);
      this.myReaction = {
        id: "",
        index: -1,
        reaction: "",
      };

      this.axios
        .delete(
          `${this.$config.APIURL}/api/messenger/threads/${this.message.thread_id}/messages/${this.message.id}/reactions/${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then(() => {
          this.reacted = false;
          this.reactions[this.myReaction.reaction] = this.reactions[
            this.myReaction.reaction
          ].filter((rt, i) => i != this.myReaction.index);
          this.myReaction = {
            id: "",
            index: -1,
            reaction: "",
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    giveReact(react) {
      this.myReaction = {
        id: "",
        reaction: react,
        index: -1,
      };
      this.axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.message.thread_id}/messages/${this.message.id}/reactions`,
          { reaction: react },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((res) => {
          const r = Object.keys(this.reactions).find((key) => key == react);
          if (r && (this.reactions[r] || this.reactions[r].length)) {
            this.reactions[r].push(res.data);
          } else if (r && !this.reactions[r]) {
            this.reactions[r] = [res.data];
          } else {
            this.reactions[react] = [res.data];
          }
          this.reacted = true;
          this.myReaction = {
            id: res.data.id,
            reaction: res.data.reaction,
            index: this.reactions[react].length - 1,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToOriginalMessage(message) {
      let bubble = document.getElementsByClassName(message.id)[0];
      if (bubble) {
        bubble.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => {
          bubble.classList.add("reply-message-animation");
        }, 500);
        setTimeout(() => {
          bubble.classList.remove("reply-message-animation");
        }, 2000);
      }
    },
    replace(url) {
      return url.replace(/&amp;/g, "&");
    },
    toggleEmojiList() {
      this.showEmojiList = !this.showEmojiList;
    },
    replyMessage(messageId) {
      this.$emit("reply-message", messageId);
    },
    formatTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    downloadFile(link) {
      const anchor = document.createElement("a");
      anchor.href = link;
      anchor.download = true;
      anchor.target = "_blank";
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    },
    // Your methods go here
  },
};
</script>

<style scoped>
/* Your styles go here */
.reply-message-animation {
  animation: fade 2s infinite ease;
}
@keyframes fade {
  from {
    background-color: #b5a3f7;
  }
  to {
    background-color: transparent;
  }
}
</style>
